import styles from "./BookingSuccess.module.css";
import { Back, Helper, } from "../components";
import { BackArrow, } from "../icons";
import { Oval } from  'react-loader-spinner';
import { useParams, useNavigate, useSearchParams, } from "react-router-dom";
import React, { useEffect, useState, useRef, } from "react";


const BookingSuccess = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
    const bookingId = searchParams.get('bookingId') || '';
	const [booking, setBooking] = useState({});
	const [invalidBookingId, setInvalidBookingId] = useState(false);
	const packageInfoRef = useRef(null);
	const [packageInfoHeight, setPackageInfoHeight] = useState('auto');
	const tokenAmountPercentage = 0.10;

	useEffect(() => {
      if (packageInfoRef && packageInfoRef.current && packageInfoHeight === 'auto') {
        let height = 0;
        for (let c of packageInfoRef.current.children) {
          if (height < c.clientHeight) height = c.clientHeight;
        }  
        setPackageInfoHeight(height);
      }
    });

	const getAnalyticsCategory = () => {
		try {
	      return `BookingSuccess_${booking.experience.title.split(' ').join('_')}`;
	    } catch(err) {
	      return 'BookingSuccess';
	    }
	};

	const trackAnalytics = (action, label) => {
		Helper.trackAnalytics(getAnalyticsCategory(), action, label);
	};
 
	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/bookings/${bookingId}/details`)
	      	.then((response) => response.json())
	      	.then((res) => {
	        	if (res.data) setBooking(res.data);
	        	else setInvalidBookingId(true);
	     	})
	     	.catch((err) => {
	     		setInvalidBookingId(true);
	        	console.log(err.message);
	     	});
	}, {});

  	return(
   		<div className={styles.bookingSuccess}>
   			{invalidBookingId && 
		        <h1 style={{ textAlign: 'center', margin: '80px 50px'}}>Apologies! XPHive does not have this booking in the system.</h1>
		    }

		    {(!invalidBookingId && Object.keys(booking).length == 0) ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		    /> : '' }

		    { Object.keys(booking).length != 0 ?
		        <div>
			        <div className={styles.bookingPage}>
			        	<i onClick={(e) => { trackAnalytics('Click_Back_from_booking_success', 'Booking_Success_Page'); navigate('/'); }} className={styles.backIcon}><span style={{position: 'absolute', left: '11px', top: '11px'}}><BackArrow /></span></i>
			        	
          				<h1 className={styles.pageHeading}>Booking Success</h1>

          				<div>
          					<div className={styles.topSection}>
			        			<h2>{booking.experience.title}</h2>
			        			<p>{booking.experience.subtitle}</p>
			        		</div>

          					{booking.packages.map((pack, i) => {
			              		return(
			              			<div className={styles.packageWrap} ref={packageInfoRef} className={`${styles.packageWrap} ${styles.selectedpackageWrap}`}>
			              				<div className={styles.packageInfo} style={{height: packageInfoHeight + 30}}>
				              				<h5>{pack.id.title}</h5>
				              				<label>₹{pack.id.price.toLocaleString()}/-</label>
				              				<p>{pack.id.description}</p>

				              				<div className={styles.counts} style={{bottom: '11px'}}>
												<span>{'x ' + pack.count}</span>
											</div>
										</div>
			              				<div className={styles.packageImgWrap} style={{backgroundImage: `url(${pack.id.image})`, height: packageInfoHeight + 52}}></div>
			              			</div>
			              		)
			              	})}

          					<ul className={styles.recurrencesList}>
	          					<li className={styles.selectedRecurrence}>
			        				<span>{`${new Date(booking.recurrence.dates.start).getDate()} ${new Date(booking.recurrence.dates.start).toLocaleString('default', { month: 'short' })}  - ${new Date(booking.recurrence.dates.end).getDate()} ${new Date(booking.recurrence.dates.end).toLocaleString('default', { month: 'short' })}`}</span>
			        				<label>Slot</label>
			        			</li>
		        			</ul>
          				</div>

          				<div className={styles.membersList}>			        		
			        		<div className={styles.priceDetails}>
				        		<ul>
				        			<li>
				        				Token Amount ({booking.payment.status === 'PROCESSED' ? 'PAID' : 'PENDING'})
				        				{booking.packages.map((pack) => {
				        					return(
				        						<p>{`₹${(pack.id.price * tokenAmountPercentage).toLocaleString()}`} x {pack.count}<span>{`₹${(pack.id.price * tokenAmountPercentage * pack.count).toLocaleString()}`}</span></p>
				        					);
				        				})}
				        				<span className={styles.finalAmount}>{`₹${(booking.tokenAmount).toLocaleString()}`}</span>
				        			</li>

				        			<li>
				        				Total Amount
				        				{booking.packages.map((pack) => {
				        					return(
				        						<p>{`₹${pack.id.price.toLocaleString()}`} x {pack.count}<span>{`₹${(pack.id.price * pack.count).toLocaleString()}`}</span></p>
				        					);
				        				})}
				        				<span className={styles.finalAmount}>{`₹${booking.price.toLocaleString()}`}</span>
				        			</li>

				        			<li  className={styles.priceNote}>
				        				Note- Token amount blocks a slot for your but remaining payment (₹{(booking.price - booking.tokenAmount).toLocaleString()}) needs to be paid before the experience start date
				        			</li>
				        		</ul>
				        	</div>
			        	</div>
			        </div>
		        </div>
		    : ''}
   		</div>
  	);
};

export default BookingSuccess;
