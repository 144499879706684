import styles from "./GetInTouch.module.css";
import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { Helper, Back, } from "../components";


const GetInTouch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const experienceId = searchParams.get('experienceId') || '';
  const [formState, setFormState] = useState({
    phone: '',
    name: '',
    email: '',
    message: '',
  });
  const [formErrorState, setFormErrorState] = useState({
    phone: false,
    name: false,
    email: false,
    message: false,
  });
  const [submittingForm, setSubmittingForm] = useState(false);
  const [leadSubmitted, setLeadSubmitted] = useState(Helper.storage.getItem(`GetInTouch_${experienceId}`) || false);

  const onChangeInput = (e, type) => {
      e.preventDefault();

      if (type === 'phone') {
        formErrorState['phone'] = false;
        setFormErrorState({...formErrorState});
        if (e.target.value.length < 11) {
          formState['phone'] = e.target.value;
          setFormState({...formState});
        }
      } else if (type === 'name') {
        formErrorState.name = false;
        setFormErrorState({...formErrorState});
        const result = e.target.value.replace(/[^a-z ]/gi, '');
        formState.name = result;
        setFormState({...formState});
      } else if (type === 'email') {
        formErrorState.email = false;
        setFormErrorState({...formErrorState});
        formState.email = e.target.value;
        setFormState({...formState});
      } else {
        formErrorState.message = false;
        setFormErrorState({...formErrorState});
        formState.message = e.target.value;
        setFormState({...formState});
      }
    };

  const setLeads = () => {
    setLeadSubmitted(true);
    Helper.storage.setItem(`GetInTouch_${experienceId}`, true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.name) {
      formErrorState['name'] = true;
      setFormErrorState({...formErrorState});
    }

    if (formState.phone.length != 10) {
      formErrorState['phone'] = true;
      setFormErrorState({...formErrorState});
    }
    
    if (!formState.email) {
      formErrorState['email'] = true;
      setFormErrorState({...formErrorState});
    }

    if (!formState.message) {
      formErrorState['message'] = true;
      setFormErrorState({...formErrorState});
    }


    if (!submittingForm && formState.name && formState.phone && formState.email && formState.message && !formErrorState.name 
      && !formErrorState.phone && !formErrorState.email && !formErrorState.message) {
      Helper.trackAnalytics(`GetInTouch_${experienceId}`, 'Click_Submit_details', formState.phone);
      setSubmittingForm(true);

      await fetch(`${global.config.SERVER_URL}/api/v1/leads`, {
        method: 'POST',
        body: JSON.stringify({
          name: formState.name,
          email: formState.email,
          phone: formState.phone,
          message: formState.message,
          experienceId,
          type: 'getInTouch',
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setSubmittingForm(false);
          if (data.status == 'success') setLeads();
        })
        .catch((err) => {
          setSubmittingForm(false);
          console.log(err.message);
        });
    }
  };
  
  return (
    <div className={styles.getInTouchContainer}>
      <i className={styles.backIcon}><Back style={{position: 'absolute', left: '23px', top: '25px'}} analyticsCategory={`GetInTouch_${experienceId}`} /></i>
      <h1 className={styles.pageHeading}>Get in Touch</h1>

      {!leadSubmitted &&
        <div>
          <form onSubmit={handleSubmit}>
            <div>
              <label className={styles.label}>What is your name? *</label>
              <input className={formErrorState.name ? styles.invalid : styles.valid} type="text" placeholder="Name" value={formState.name} onChange={e => onChangeInput(e, 'name')} />
            </div>

            <div>
              <label className={styles.label}>Please share your number... *</label>
              <input className={formErrorState.phone ? styles.invalid : styles.valid} type="number" placeholder="+91 -" value={formState.phone} onChange={e => onChangeInput(e, 'phone')} />
            </div>

            <div>
              <label className={styles.label}>Please share your Mail ID... *</label>
              <input className={formErrorState.email ? styles.invalid : styles.valid} type="email" placeholder="Email" value={formState.email} onChange={e => onChangeInput(e, 'email')} />
            </div>

            <div>
              <label className={styles.label}>How can we assist you today? *</label>
              <textarea className={formErrorState.message ? styles.invalid : styles.valid} placeholder="Type your query here..." value={formState.message} rows="4" onChange={e => onChangeInput(e, 'message')} />
            </div>


            <p>Your personal information is safe with us and will not be misused in any way. For more details please read our <a href="/privacy-policy">Privacy Policy</a></p>
            <button type="submit">Submit your query</button>
          </form>
        </div>
      }

      {leadSubmitted && 
        <div>
          <h3 className={styles.success}>Thank you for contacting us!! We will revert back soon.</h3>
        </div>
      }
    </div>
  )
}

export default GetInTouch;
